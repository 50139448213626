<template>
  <div>
    {{ recurringReservation }}
  </div>
</template>
<script>
import { isNotUndefinedAndNotNull } from "@/utils/classes";

export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    }
  },
  computed: {
    recurringReservation() {
      return isNotUndefinedAndNotNull(this.item.recurrence) ? this.$t('general.actions.yes')
          : this.$t('general.actions.no');
    },
  }
}
</script>
